import React, { useState } from 'react';

import "./Gift.css";

import { GlobalConsumer } from "../../context/Consumer";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CardActions from '@mui/material/CardActions';
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import CheckIcon from '@mui/icons-material/Check';
import MuiAlert from '@mui/material/Alert';
import Divider from "@mui/material/Divider";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

const address = `
    Jalan Ki Hajar Dewantara,
    No rumah 432,
    RT 001 / RW 001,
    Desa Padangan,
    Kecamatan Kayen Kidul,
    Kabupaten Kediri,
    Kode Pos 64183,
    Rumah Pagar Biru / rumah bpk. Sunarto
`

const Gift = (props) => {
    const [stateToast, setStateToast] = useState({
        duration: 1300,
        open: false,
        Transition: Slide,
        value: ""
    });

    const handleClickToast = (Transition) => () => {
        const toastMessage = `Berhasil Menyalin Alamat`;
        navigator.clipboard.writeText(address)
        setStateToast({
            ...stateToast,
            open: true,
            value: toastMessage,
        });
    };

    const handleCloseToast = () => {
        setStateToast({
            ...stateToast,
            open: false,
        });
    };

    return (
        <div>
            <Card sx={{ marginTop: 2, textAlign: "center" }} elevation={3}>
                <CardMedia
                    style={{ height: 180 }}
                    component="img"
                    height="140"
                    image={`${process.env.PUBLIC_URL}/assets/gift/gift.jpeg`}
                    alt="green iguana" />
                <CardContent style={{ height: 150 }}>
                    <Typography gutterBottom variant="h5" component="div">
                        Gift
                    </Typography>
                    <Divider variant="middle" style={{ margin: "0 0 15px 0" }} />
                    <Typography variant="body2" color="text.primary">
                        {address}
                    </Typography>
                </CardContent>
                <CardActions style={{ height: 60, display: "block" }}>
                    <Button onClick={handleClickToast(SlideTransition)} variant="contained" style={{ width: 200 }} startIcon={<ContentCopyIcon />}>
                        Copy Address
                    </Button>
                </CardActions>
            </Card>
            <Snackbar
                style={{ display: "inline-grid" }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={stateToast.duration}
                open={stateToast.open}
                onClose={handleCloseToast}
                TransitionComponent={stateToast.Transition}
                key={stateToast.Transition.name}>
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" sx={{ width: 'inherit', textAlign: 'center' }}>
                    {stateToast.value}
                </Alert>
            </Snackbar>
        </div>
    );
};


export default GlobalConsumer(Gift);