import axios from "axios";

// Create an axios instance
const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Base URL dari .env
    timeout: 30000, // Timeout 10 detik
});

// Request Interceptor (Opsional, jika butuh token)
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response Interceptor (Opsional, untuk error handling global)
apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error("API Error:", error.response || error.message);
        return Promise.reject(error);
    }
);

export default apiClient;