import apiClient from "./client";

const invitationApi = {
    getUser: () => apiClient.get("/invitation/user"),

    getComment: () => apiClient.get("/invitation/comment"),
    postComment: (data) => apiClient.post("/invitation/comment", data),

    getPayment: () => apiClient.get("/invitation/payment"),

    postLog: () => apiClient.post("/invitation/log"),

    getEvent: () => apiClient.get("/invitation/event"),

    getJourney: () => apiClient.get("/invitation/journey"),
};

export default invitationApi;