import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import ScrollStatus from "../../components/ScrollStatus/ScrollStatus";
import Home from "../../components/Home/Home";
import PhotoLibrary from "../../components/PhotoLibrary/PhotoLibrary";
import Journey from "../../components/Journey/Journey";
import Event from "../../components/Event/Event";
import Navigation from "../../components/Navigation/Navigation";
import Giving from "../../containers/Giving/Giving";
import Wishes from "../../containers/Wishes/Wishes";
import invitationApi from "../../api/invitation";
import { jwtDecode } from "jwt-decode";
import { GlobalConsumer } from "../../context/Consumer";
import "./Wedding.css";

const Wedding = (props) => {
    const { updateUser } = props; // Destructure di luar useEffect
    const homeSection = useRef(null);
    const coupleSection = useRef(null);
    const eventSection = useRef(null);
    const givingSection = useRef(null);
    const wishesSection = useRef(null);

    const navigate = useNavigate();

    /* eslint-disable */
    const newLogin = () => {
        try {
            const params = new URLSearchParams(window.location.search);
            const paramToken = params.get("token")
            localStorage.setItem("token", paramToken);
            jwtDecode(paramToken)

            const newURL = window.location.origin + window.location.pathname;
            window.history.replaceState({}, document.title, newURL);
        } catch (error) {
            navigate("/");
        }
    }

    /* eslint-disable */
    const oldLogin = () => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/");
        }
    }

    useEffect(() => {
        oldLogin()

        // Hit API saat halaman dimuat
        const fetchData = async () => {
            try {
                await invitationApi.postLog();

                const respData = await invitationApi.getUser();
                const data = respData.data.data;

                updateUser({
                    email: data.email,
                    full_name: data.full_name,
                    picture: data.picture,
                });
            } catch (error) {
                navigate("/");
            }
        };

        fetchData();
    }, [navigate, updateUser]);

    return (
        <React.Fragment>
            <Container
                maxWidth="md"
                className="main-content-layout"
                sx={{ pb: 8 }} // Tambahkan padding bawah
            >
                <ScrollStatus />
                <div ref={homeSection}>
                    <Home />
                </div>
                <div ref={coupleSection} style={{ marginTop: 20 }}>
                    <Journey />
                    <PhotoLibrary />
                </div>
                <div ref={eventSection}>
                    <Event />
                </div>
                <div ref={givingSection}>
                    <Giving />
                </div>
                <div ref={wishesSection}>
                    <Wishes />
                </div>
                <Navigation
                    couple={coupleSection}
                    wishes={wishesSection}
                    home={homeSection}
                    giving={givingSection}
                    event={eventSection} />
            </Container>
        </React.Fragment>
    );
};

export default GlobalConsumer(Wedding);
