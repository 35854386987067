import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { pink } from '@mui/material/colors';
import "./Journey.css";
import invitationApi from "../../api/invitation";
import { GlobalConsumer } from "../../context/Consumer";
import Divider from "@mui/material/Divider";

const Journey = (props) => {
    const [activeStep, setActiveStep] = useState(0);
    const [steps, setSteps] = useState([]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const respData = await invitationApi.getJourney();
                setSteps(respData.data.data);
            } catch (error) {
                console.error("Error fetching payment:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center">
            <Grid item xs={3}>
                <Card sx={{ marginBottom: 2, textAlign: "center" }} elevation={3} className="journey">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">Couple and Journey</Typography>
                        <Divider variant="middle" style={{ margin: "15px 0 0 0" }} />
                    </CardContent>
                    <CardMedia
                        component="img"
                        height="140"
                        image={`${process.env.PUBLIC_URL}/assets/journey/male.png`}
                        alt="male" />
                    <Typography gutterBottom variant="h5">Alhamsya Bintang Dyasta, S.Kom</Typography>
                    <Typography variant="overline" display="block" gutterBottom>Putra Kedua dari</Typography>
                    <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: "bold", margin: "0 15px" }}>Bapak (Alm) Sunarto, S.E & Ibu Sri Indiastutik, S.Pd</Typography>
                    <div className='separator'>
                        <FavoriteIcon sx={{ fontSize: 50, color: pink[500] }} />
                    </div>
                    <CardMedia
                        component="img"
                        height="140"
                        image={`${process.env.PUBLIC_URL}/assets/journey/female.png`}
                        alt="femlae" />
                    <Typography gutterBottom variant="h5">Elizabeth Keen, PhD</Typography>
                    <Typography variant="overline" display="block" gutterBottom>Putri Pertama dari</Typography>
                    <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: "bold", margin: "0 15px" }}>Bapak Tom Cruise, S.H & Ibu Shin Min-a</Typography>

                    <CardContent sx={{ marginLeft: 3, marginRight: 3 }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepLabel
                                        optional={
                                            index === 2 ? (
                                                <Typography variant="caption">Last Story</Typography>
                                            ) : null
                                        }>
                                        {step.title}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography sx={{ textAlign: "left" }}>{step.description}</Typography>
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    sx={{ mt: 1, mr: 1 }}>
                                                    {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                                </Button>
                                                <Button
                                                    disabled={index === 0}
                                                    onClick={handleBack}
                                                    sx={{ mt: 1, mr: 1 }}>
                                                    Back
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={3} sx={{ p: 3, marginBottom: 2, textAlign: "center" }}>
                                <Typography sx={{ textAlign: "center" }}>Finally we got married</Typography>
                                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                    Tell our journey first
                                </Button>
                            </Paper>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};


export default GlobalConsumer(Journey);