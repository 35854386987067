import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { GlobalConsumer } from "../../context/Consumer";
import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import invitationApi from "../../api/invitation";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import "./Attendance.css";

const ATTENDANCE_STATUS = {
    HADIR: "present",
    TIDAK_HADIR: "absent",
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Attendance = ({ refreshComments }) => {
    const [presence, setPresence] = useState('');
    const [comment, setComment] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State untuk severity
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        setPresence(event.target.value);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = async () => {
        const payload = {
            message: comment,
            presence: presence,
        };

        setIsLoading(true);

        try {
            const respData = await invitationApi.postComment(payload);

            setSnackbarMessage(respData.data.message);
            setSnackbarSeverity("success");
            refreshComments();

            // reset
            setComment('');
            setPresence('');
        } catch (error) {
            const apiErrorMessage = error.response?.data?.message || "Terjadi kesalahan pada server.";
            setSnackbarMessage(apiErrorMessage);
            setSnackbarSeverity("error");
        } finally {
            setIsLoading(false);
            setSnackbarOpen(true);
        }
    };

    return (
        <div style={{ textAlign: "center" }}>
            <TextField
                id="outlined-multiline-static"
                label="Komentar"
                multiline
                rows={5}
                fullWidth
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            />

            <FormControl fullWidth style={{ margin: "8% 0" }}>
                <InputLabel id="demo-simple-select-label">Kehadiran</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={presence}
                    label="Kehadiran"
                    onChange={handleChange}>
                    <MenuItem value={ATTENDANCE_STATUS.HADIR}>Hadir</MenuItem>
                    <MenuItem value={ATTENDANCE_STATUS.TIDAK_HADIR}>Tidak Hadir</MenuItem>
                </Select>
            </FormControl>
            <Button
                variant="contained"
                style={{ margin: 10, width: 200 }}
                endIcon={!isLoading && <SendIcon />}
                onClick={handleSubmit}
                disabled={isLoading}
            >
                {isLoading ? "Sending..." : "Send"}
            </Button>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};


export default GlobalConsumer(Attendance);