import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";
import GoogleIcon from '@mui/icons-material/Google';
import { useGoogleLogin } from "@react-oauth/google";
import '@fontsource/caveat';
import axios from "axios";
import { GlobalConsumer } from "../../context/Consumer";
import "./Landing.css";
import rootApi from "../../api/root";
import { browserName } from 'react-device-detect';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://dyasta.com">
                Alhamsya Bintang Dyasta
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Landing = (props) => {
    const navigate = useNavigate(); // Hook untuk navigasi
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [allowedBrowsers, setAllowedBrowsers] = useState(["Chrome"]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const respData = await rootApi.getRoot({ platform: browserName });
                setAllowedBrowsers(respData.data.data.browser_name);
                console.log(respData);
            } catch (error) {
                setAllowedBrowsers(["Chrome"]);
                console.error("Error fetching:", error);
            }
        };

        fetchData();
        localStorage.clear();
    }, []);

    /* eslint-disable */
    const newLogin = () => {
        const intentUrl = `intent://${window.location.origin}/#Intent;scheme=https;end`
        window.location.href = intentUrl;
        const linkURL = `https://accounts.google.com/o/oauth2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API_CALLBACK}&response_type=code&scope=email profile openid`
        // window.open(window.location.origin, "_system");
        return linkURL
    };

    /* eslint-disable */
    const oldLogin = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            setIsLoading(true);
            try {
                const respData = await axios.get(
                    `${props.api_url}/auth/google/callback?code=${codeResponse.code}&platform=${browserName}`
                );

                const token = respData.data.data;
                localStorage.setItem("token", token);

                navigate("/nikah");
            } catch (error) {
                setErrorMessage(error.response?.data?.message || 'Login failed!');
                setOpenSnackbar(true);
            } finally {
                setIsLoading(false);
            }
        },
        flow: "auth-code",
    });

    const login = () => {
        if (!allowedBrowsers.includes(browserName)) {
            setErrorMessage(`Browser "${browserName}" tidak didukung. Silakan gunakan salah satu dari browser berikut: ${allowedBrowsers.join(", ")}.`);
            setOpenSnackbar(true);
            return
        }
        oldLogin();
    }

    return (
        <Grid
            container
            component="main"
            sx={{ height: '100vh' }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${`${process.env.PUBLIC_URL}/assets/wedding/wedding.jpeg`})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    '&::after': {
                        content: '""', // Overlay
                        display: { xs: 'block', sm: 'none' }, // Hanya aktif di mobile
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Warna putih transparan 80%
                        zIndex: 1, // Overlay di atas gambar
                    },
                }} />
            <Grid
                item
                xs={12}
                sm={8}
                md={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
                elevation={6}
                sx={{
                    backgroundImage: {
                        xs: `url(${`${process.env.PUBLIC_URL}/assets/wedding/wedding.jpeg`})`, // Tambahkan gambar juga di mobile
                        sm: 'none', // Di layar besar hanya fokus pada grid background utama
                    },
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <Box sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center', // Teks di tengah
                    zIndex: 3, // Pastikan <Box> di atas overlay
                    position: 'relative', // Pastikan tetap independen
                }}>
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{
                            fontFamily: '"Caveat", cursive', // Gaya font Caveat
                            fontWeight: 'bold', // Bisa diganti ke 400 jika tidak ingin terlalu tebal
                            textAlign: 'center',
                            mt: 2, // Margin atas
                            mb: 2, // Margin bawah
                            fontSize: {
                                xs: '3rem',
                                sm: '4rem'
                            }, // Ukuran font: kecil untuk mobile, besar untuk layar lainnya
                        }}
                    >
                        Wedding Invitation
                    </Typography>
                    <CardMedia
                        component="img"
                        sx={{
                            height: {
                                xs: "50%", // Tinggi untuk mobile
                                md: "60%", // Tinggi untuk desktop
                            },
                            width: {
                                xs: "90%", // Lebar untuk mobile
                                md: "50%", // Lebar untuk desktop
                            },
                            objectFit: 'cover',
                            borderRadius: "50%", // Opsional: Menambahkan efek lingkaran
                            margin: "0 auto", // Memusatkan gambar secara horizontal
                        }}
                        image={`${process.env.PUBLIC_URL}/assets/wedding/couple.png`}
                        alt="wedding" />
                    <Button
                        onClick={login}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        startIcon={<GoogleIcon />}
                        disabled={isLoading}
                    >
                        {isLoading ? "Loading..." : "Open"}
                    </Button>
                    <Copyright sx={{ mt: 5 }} />

                </Box>
            </Grid>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default GlobalConsumer(Landing);
