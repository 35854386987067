import React, { Component } from "react";

import { RootContext, Data } from "./Context";

const Provider = RootContext.Provider;

const GlobalProvider = (Children) => {
  return class ParentComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        ...Data,
        updateUser: this.updateUser,
      };
    }

    updateUser = (newUser) => {
      this.setState({
        user: newUser, // Gantikan data lama dengan data baru
      });
    };

    render() {
      return (
        <Provider value={this.state}>
          <Children {...this.props} />
        </Provider>
      );
    }
  };
};

export default GlobalProvider;
