import { createContext } from "react";

export const RootContext = createContext();

const API_URL = process.env.REACT_APP_API_URL || "https://api.dyasta.com";

export const Data = {
  api_url: API_URL,
  user: {
    email: "",
    full_name: "",
    picture: ""
  }
};
