import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { GlobalConsumer } from "../../context/Consumer";

import "./Home.css";
import Divider from "@mui/material/Divider";

const Home = (props) => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center">
            <Grid item xs={3}>
                <Card sx={{ marginTop: 2, textAlign: "center" }} elevation={3} className="wedding">
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">You’re Invited {props.user.full_name},</Typography>
                        <Typography gutterBottom variant="h5" component="div">To The Wedding Of</Typography>
                        <Divider variant="middle" style={{ margin: "15px 0 0 0" }} />
                    </CardContent>
                    <CardMedia
                        component="img"
                        height="140"
                        image={`${process.env.PUBLIC_URL}/assets/wedding/couple.png`}
                        alt="wedding" />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">Alhamsya & Elizabeth Keen</Typography>
                        <Typography variant="body2" color="text.secondary">Assalamu'alaikum Warahmatullahi Wabarakatuh</Typography>
                        <Typography variant="body2" color="text.secondary">
                            Maha suci Allah yang telah menciptakan mahluk-Nya berpasang-pasangan. Ya Allah, perkenankanlah kami merangkaikan kasih sayang yang Kau ciptakan diantara kami untuk mengikuti Sunnah Rasul-Mu dalam rangka membentuk keluarga yang sakinah, mawaddah, warahmah.
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};


export default GlobalConsumer(Home);