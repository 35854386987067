import React, { useEffect, useState } from "react";
import "./Payment.css";
import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { GlobalConsumer } from "../../context/Consumer";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import PaymentIcon from '@mui/icons-material/Payment';
import Avatar from '@mui/material/Avatar';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import CheckIcon from "@mui/icons-material/Check";
import MuiAlert from '@mui/material/Alert';
import invitationApi from "../../api/invitation";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

const Payment = (props) => {
    const [open, setOpen] = React.useState(false);
    const [payment, setPayment] = useState([]);


    const [stateToast, setStateToast] = React.useState({
        duration: 1300,
        open: false,
        Transition: Slide,
        value: ""
    });

    // Fungsi untuk mengunci scroll
    const lockScroll = () => {
        const scrollPosition = window.scrollY;
        document.body.style.position = "fixed";
        document.body.style.top = `-${scrollPosition}px`;
        document.body.style.width = "100%";
    };

    // Fungsi untuk membuka kembali scroll
    const openScroll = () => {
        const scrollPosition = Math.abs(parseInt(document.body.style.top || "0", 10));
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, scrollPosition);
    };

    // Handle membuka Dialog
    const handleClickOpen = () => {
        setOpen(true);
        lockScroll();
    };

    // Handle menutup Dialog
    const handleClose = () => {
        setOpen(false);
        openScroll();
    };

    // Handle Toast klik
    const handleClickToast = (Transition, value) => () => {
        setOpen(false); // Tutup Dialog
        openScroll(); // Pastikan scroll terbuka
        const toastMessage = `Berhasil menyalin ${value.account_number} (${value.name})`;
        navigator.clipboard.writeText(value.account_number);

        setStateToast({
            ...stateToast,
            open: true,
            value: toastMessage,
        });
    };

    const handleCloseToast = () => {
        setStateToast({
            ...stateToast,
            open: false,
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const respData = await invitationApi.getPayment();
                setPayment(respData.data.data);
            } catch (error) {
                console.error("Error fetching payment:", error);
            }
        };

        fetchData();
    }, []);

    // Cleanup scroll lock saat komponen unmount
    useEffect(() => {
        return () => {
            document.body.style.position = "";
            document.body.style.top = "";
            document.body.classList.remove("no-scroll"); // Pastikan scroll kembali normal
        };
    }, []);

    return (
        <div aria-hidden="true">
            <Card sx={{ marginTop: 2, textAlign: "center" }} elevation={3}>
                <CardMedia
                    sx={{ height: 180 }}
                    component="img"
                    height="140"
                    image={`${process.env.PUBLIC_URL}/assets/gift/envelope.jpeg`}
                    alt="green iguana" />
                <CardContent style={{ height: 150 }}>
                    <Typography gutterBottom variant="h5" component="div">
                        Digital Envelope
                    </Typography>
                    <Divider variant="middle" style={{ margin: "0 0 15px 0" }} />
                    <Typography variant="body2" color="text.primary">
                        Sebagai ungkapan tanda kasih, apabila Bapak/Ibu/Sdr/i ingin memberikan kado, bisa
                        dikirimkan melalui transfer ke amplop digital
                    </Typography>
                </CardContent>
                <CardActions style={{ height: 60, display: "block" }}>
                    <Button variant="contained" startIcon={<AccountBalanceWalletIcon />}
                        onClick={handleClickOpen}>
                        Open Ampao Digital
                    </Button>
                </CardActions>
            </Card>
            <Dialog
                style={{ top: "45%" }}
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                disableScrollLock // penguncian scrol
            >
                <AppBar sx={{ position: 'sticky' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close">
                            <PaymentIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Salin Digital Envelope
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            Close
                        </Button>
                    </Toolbar>
                </AppBar>
                <List sx={{ pt: 0 }}>
                    {payment.map((data, idx) => (
                        <div key={data.id.toString()}>
                            <ListItem onClick={handleClickToast(SlideTransition, data)}>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={data.name}
                                        src={data.picture}
                                        sx={{ width: 50, height: 50 }} />
                                </ListItemAvatar>
                                <ListItemText style={{ marginLeft: 20 }} color="text.primary">
                                    <Typography variant="subtitle2" display="block" gutterBottom sx={{ fontWeight: 'bold' }}>
                                        {data.name}
                                    </Typography>
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {data.account_name}
                                    </Typography>
                                    <Typography variant="button" display="block" gutterBottom>
                                        {data.account_number}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Divider />
                        </div>
                    ))}
                </List>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={stateToast.duration}
                open={stateToast.open}
                onClose={handleCloseToast}
                TransitionComponent={stateToast.Transition}
                key={stateToast.Transition.name}>
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" sx={{ width: 'inherit', textAlign: 'center' }}>
                    {stateToast.value}
                </Alert>
            </Snackbar>
        </div>

    );
};


export default GlobalConsumer(Payment);