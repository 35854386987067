import React from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";

import "./Comment.css";

import { GlobalConsumer } from "../../context/Consumer";

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    color: theme.palette.text.primary,
}));

const Comment = ({ comments }) => {
    if (!comments || comments.length === 0) {
        return (
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
                Tidak ada komentar.
            </Typography>
        );
    }

    return (
        <div>
            {comments.map((comment, index) => (
                <StyledPaper key={index} sx={{ my: 1, mx: "auto", p: 2 }} elevation={3}>
                    <Grid container wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Avatar
                                alt={comment.full_name || "Anonymous"}
                                src={comment.picture || "https://mui.com/static/images/avatar/1.jpg"}
                                sx={{ width: 50, height: 50 }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" display="block">
                                {comment.created_at}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                                {comment.full_name || "Anonymous"}
                            </Typography>
                            <Typography variant="overline" sx={{ display: 'block' }}>
                                {comment.presence}
                            </Typography>
                            <Divider variant="middle" sx={{ margin: "0 0 10px 0" }} />
                            <Typography
                                sx={{
                                    wordWrap: "break-word", // Membungkus kata panjang agar tidak melewati box
                                    whiteSpace: "pre-wrap", // Menjaga format asli dengan baris baru
                                    overflow: "hidden", // Menghindari teks meluap dari kontainer
                                    textOverflow: "ellipsis", // Opsional: Tambahkan ellipsis jika ingin memotong teks
                                    maxWidth: "75%", // Batasi teks agar sesuai dengan lebar kontainer
                                }}
                            >
                                {comment.message}
                            </Typography>
                        </Grid>
                    </Grid>
                </StyledPaper>
            ))}
        </div>
    );
};


export default GlobalConsumer(Comment);