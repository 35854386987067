import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import invitationApi from "../../api/invitation";

import "./Event.css";

import { GlobalConsumer } from "../../context/Consumer";
import Divider from "@mui/material/Divider";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    height: 500,
}));

const Event = (props) => {
    const [events, setEvents] = useState([]); // State untuk menyimpan data acara
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await invitationApi.getEvent();
                setEvents(response.data.data); // Simpan data acara dari API
            } catch (error) {
                console.error("Error fetching events:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, []);

    if (loading) {
        return <Typography>Loading events...</Typography>;
    }

    if (!events) {
        return <Typography>No events available</Typography>;
    }

    return (
        <Box sx={{ width: '100%', marginBottom: 3 }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={6}>
                    <Item>
                        <Typography variant="h5" gutterBottom>
                            {events.marriage_contract.title}
                        </Typography>
                        <Divider variant="middle" style={{ margin: "15px 0 0 0" }} />
                        <div style={{ marginTop: 20 }}>
                            <InsertInvitationIcon sx={{ fontSize: 70 }} color="primary" />
                            <Typography variant="subtitle1" gutterBottom>
                                {events.marriage_contract.date}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Pukul {events.marriage_contract.time} WIB
                            </Typography>
                            <Button
                                onClick={() =>
                                    window.open(events.marriage_contract.calender_url, "_blank")
                                }
                                variant="contained" style={{ marginTop: 10 }}>
                                Save the Date
                            </Button>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Typography variant="h6" gutterBottom>
                                {events.marriage_contract.place}
                            </Typography>
                            <MapIcon sx={{ fontSize: 70 }} color="primary" />
                            <Typography variant="subtitle2" gutterBottom>
                                {events.marriage_contract.location_name}
                            </Typography>
                            <Button
                                onClick={() => window.open(events.marriage_contract.location_url, "_blank")}
                                variant="contained"
                                style={{ marginTop: 10 }}
                                disabled={events.marriage_contract.btn_map_disabled}
                            >
                                Buka Peta
                            </Button>
                        </div>
                    </Item>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Item>
                        <Typography variant="h5" gutterBottom>
                            {events.wedding_reception.title}
                        </Typography>
                        <Divider variant="middle" style={{ margin: "15px 0 0 0" }} />
                        <div style={{ marginTop: 20 }}>
                            <CalendarMonthIcon sx={{ fontSize: 70 }} color="primary" />
                            <Typography variant="subtitle1" gutterBottom>
                                {events.wedding_reception.date}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Pukul {events.wedding_reception.time} WIB - Selesai
                            </Typography>
                            <Button
                                onClick={() =>
                                    window.open(events.wedding_reception.calender_url, "_blank")
                                }
                                variant="contained" style={{ marginTop: 10 }}>
                                Save the Date
                            </Button>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Typography variant="h6" gutterBottom>
                                {events.wedding_reception.place}
                            </Typography>
                            <LocationOnIcon sx={{ fontSize: 70 }} color="primary" />
                            <Typography variant="subtitle2" gutterBottom>
                                {events.wedding_reception.location_name}
                            </Typography>
                            <Button
                                onClick={() => window.open(events.wedding_reception.location_url, "_blank")}
                                variant="contained"
                                style={{ marginTop: 10 }}
                                disabled={events.wedding_reception.btn_map_disabled}
                            >
                                Buka Peta
                            </Button>
                        </div>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
};


export default GlobalConsumer(Event);